import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { supabase } from "../utils/supabaseClient.js"
import { fancyTimeFormat } from "../utils/utilitiesRendering.js"

import '../styles/pages/document.css';


const Document = () => {
  const id = useParams().id;
  const [transcription, setTranscription] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabase.from("transcripts").select("*").eq("transcript_id", id);
        console.log(data)
        if (error) {
          setError(error);
        } else {
          setTranscription(data[0]);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        console.error(error)
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <div className="transcription-page">Loading...</div>;
  }

  return (
    <div className="transcription-page">
      <Link to="/" className="button button-primary back-button">
        <span>&larr;</span> Back
      </Link>
      <h1 className="transcription-page__title">{transcription.title}</h1>
      <div className="transcription-page__summarycontainer">
        <h2>Summary</h2>
        <p className="transcription-page__summary">{transcription.summary}</p>
        {/*<ul className="transcription-page__keyinfo">
          {
            Object.keys(transcription.keyinfo).map((key, index) => {
              if (transcription.keyinfo[key] && transcription.keyinfo[key] !== "null") {
                return <li key={index}><b className="keyinfo-name">{key}:</b> {transcription.keyinfo[key]}</li>
              }
              else {
                return null;
              }
            })
          }
        </ul>*/}
      </div>
      <div className="transcription-page__timestamps">
        <h2>Timestamps</h2>
        {transcription.segments.map((timestamp, index) => 
          <div className="timestamp-block" key={index}>
            <div className="timestamp-meta">
              <div className={`timestamp-person ${typeof timestamp.speaker !== 'string' ? `speaker-${timestamp.speaker}` : timestamp.speaker.toLowerCase()}`}>{typeof timestamp.speaker !== 'string' ? `Speaker ${timestamp.speaker}` : timestamp.speaker}</div>
              <div className="timestamp-time">{fancyTimeFormat(timestamp.start) + "-" + fancyTimeFormat(timestamp.end)}</div>
            </div>
            <p className="timestamp-text">{timestamp.text}</p>
          </div>
        )}
      </div>
    </div>
  )
};

export default Document;