import React, { useState, useRef } from 'react'
import { supabase } from '../utils/supabaseClient'
import { useContext } from 'react';
import { ModeContext } from '../pages/layout';
import { sendToast } from '../utils/sendToast'
import { Link } from "react-router-dom";

import LogoWhite from "../assets/recapnote-logo-white.png"
import LogoBlack from "../assets/recapnote-logo-black.png"
import SignupImage from "../assets/signup_recapnote.png"

import '../styles/pages/auth.css'

export default function Signup() {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const agpRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { mode, setMode } = useContext(ModeContext);
  // todo: add messages for success and errormessages

  const handleSignUp = async (event) => {
    event.preventDefault()
    if (
      !passwordRef.current?.value ||
      !emailRef.current?.value ||
      !confirmPasswordRef.current?.value
    ) {
      sendToast("Please fill all the fields", "error")
      return;
    } else if (!agpRef.current?.checked){
      sendToast("Please accept the AGBs", "error")
      return;
    }
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      sendToast("Passwords doesn't match", "error")
      return;
    }

    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signUp(  { email: emailRef.current?.value, password: passwordRef.current?.value }, { redirectTo: 'http://localhost:3000/' })
      if (error) throw error
      if (!error && data) {
        sendToast("Registration Successful. Check your email to confirm your account", "info")
      }
    } catch (error) {
      sendToast("Error in Creating Account. Please reach out to the support team.", "error")
    }
    
    setLoading(false)
  }

  return (
    <div className="auth">
      <div className="auth-logo">
        {mode === "light" ? <img src={LogoBlack} alt="logo" /> : <img src={LogoWhite} alt="logo" />}
      </div>
      <div className="auth-container">
        <div className="auth-container-image">
          <img src={SignupImage} alt="signup" />
        </div>
        <div className="auth-container-content">
          <div className="auth-header">
            <h1 className="auth-title">Welcome to Recapnote</h1>
            <p className="auth-description">We want to get you going right away.</p>
            <p className="auth-description">We are customizing everything we do to your requirements, so we would love to get some information about you below:</p>
          </div>
          <form className="form-widget" onSubmit={handleSignUp}>
            <div className="inputContainer">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                className="inputField"
                type="email"
                placeholder="Your email"
                ref={emailRef}
                required={true}
              />
            </div>
            <div className="inputContainer">
              <label htmlFor="password">Password</label>
              <input 
              id="password" name="password"
              type="password" className="inputField" placeholder='********'
              ref={passwordRef}
              required={true}/>
            </div>
              <div className="inputContainer">
                <label htmlFor="confirmpassword">Confirm Password</label>
                <input 
                id="confirmpassword" name="confirmpassword"
                type="password" className="inputField" placeholder='********'
                ref={confirmPasswordRef}
                required={true}/>
              </div>
              <div className="inputContainer inputContainer--row">
                <input 
                id="agp" name="agb"
                type="checkbox"  
                ref={agpRef}
                required={true}/>
                <label htmlFor="agb">By clicking this you confirm that you have read and accept the <Link to="/tos">Terms of Service</Link></label>
              </div>
            <div className="controlContainer">
              <div className="emptyControl"></div>

              <button className={'button button-primary'} disabled={loading} type="submit">
                {loading ? <span>Loading</span> : <span>Sign Up</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}