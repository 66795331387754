import React from 'react';

import { createBrowserRouter, RouterProvider, Routes, Route } from 'react-router-dom';

import ProtectedRoute from "./utils/protectedRoute"

import Signup from './pages/signup'
import Login from './pages/login'
import Home from "./pages/home.js";
import Document from "./pages/document.js";
import PasswordReset from "./pages/passwordReset";
import Layout from "./pages/layout.js"
import TOS from "./pages/tos.js"


import './App.css';



function App( ) {
  const router = createBrowserRouter(
    [
      { element: <Layout />,
      children: [ {element: <ProtectedRoute/>,
                  children: [{ name: 'Home', path: '/', caseSensitive: true, element:  <Home/>},
                              { name: 'Transcription', path: '/transcription/:id', caseSensitive: true, element: <Document />}]},
      { name: 'Signup', path: '/signup', caseSensitive: true, element: <Signup />},
      { name: 'Login', path: '/login', caseSensitive: true, element: <Login />},
      { name: 'PasswordReset', path: '/passwordreset', caseSensitive: true, element: <PasswordReset />},
      { name: 'TOS', path: '/tos', caseSensitive: true, element: <TOS />}
    ]}
      
      ]
  );


  return (<RouterProvider router={router} />
  );
}

export default App;