import { useAuth } from "./authContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const location = useLocation();
  const path = location.pathname;
  const { user, auth } = useAuth();
  console.log(location, path, user, auth)

  if (!user) {
    return <Navigate to={"/login"} replace state={{ path: location.pathname }} />;
  }

  if (user) {
    return <Outlet />;
  }

};

export default ProtectedRoute;