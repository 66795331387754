import { useState } from "react";
import cuid from "cuid";
import axios from "axios";

import { supabase } from "../utils/supabaseClient.js"
import { useAuth } from "../utils/authContext.js";
import { sendToast } from "../utils/sendToast.js";

import "../styles/components/uploadForm.css";

function UploadForm({ setToggle }) {
    const { user } = useAuth();
    const [currentStep, setCurrentStep] = useState(1);
    const [pdfFile, setPdfFile] = useState(null); 
    const [pdfName, setPdfName] = useState(""); 
    const [audioFile, setAudioFile] = useState(null); 
    const [audioName, setAudioName] = useState(""); 
    const [audioLanguage, setAudioLanguage] = useState("English"); 
    const [applicantId, setApplicantId] = useState("");
    const [loading, setLoading] = useState(false);

    const [errorMsg, setErrorMsg] = useState(null);
    

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    }

    const handlePdfSubmit = async () => {
        console.log("submitting pdf")
        setCurrentStep(currentStep + 1);
        // Create a unique identifier for the candidate
        let applicantIdentifier = applicantId; 
        if (!applicantIdentifier) {
            applicantIdentifier = cuid();
            setApplicantId(applicantIdentifier)
        }

        // create the new candidate in the database
        const newCandidate = {
            reference_cuid: applicantIdentifier,
            name: pdfName,
            user_email: user.email,
        }

        // Insert the new candidate in the database
        supabase.from("candidates").upsert(newCandidate).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.error(error);
        });

        // Send the PDF file to the server
        // Create a FormData instance
        const formData = new FormData();
        // Append the file to it. The 'file' parameter will be the key in the multipart request.
        const fileName = `${applicantIdentifier}_${pdfName.replace(/[\s_]/g, "-")}.pdf`;
        formData.append('file', pdfFile, fileName);

        const url = `${process.env.REACT_APP_BACKEND_URL}/upload_cv`;
      
        const response = await axios.post(url, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            }
        });

        setPdfName("");
        setPdfFile(null);
    }

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    }
    // todo: feedback, setting to zero, etc.
    const handleAudioSubmit = async (event) => {
        // Submit the form data to the server
        // Create a FormData instance
        event.preventDefault();
        setLoading(true);
        let applicantIdentifier = applicantId;
        if (!applicantId || applicantId === "") {
            console.log("creating new applicant id")
            applicantIdentifier = cuid();
            setApplicantId(applicantIdentifier)

            // create the new candidate in the database
            const newCandidate = {
                reference_cuid: applicantIdentifier,
                name: audioName,
                user_email: user.email,
            }

            // Insert the new candidate in the database
            supabase.from("candidates").insert(newCandidate).then((response) => {
                console.log(response);
            }).catch((error) => {
                console.error(error);
            });
        } 
        const formData = new FormData();
        const fileExtension = audioFile.name.split(".").pop();

        const fileName = `${applicantIdentifier}_${audioName.replace(/[\s_]/g, "-")}_${audioLanguage}_${cuid()}.${fileExtension}`;
        console.log(fileName);
        formData.append('file', audioFile, fileName);
        
        const url = `${process.env.REACT_APP_BACKEND_URL}/upload_audio`; // Replace with your server URL
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        
            setAudioName("");
            setAudioFile(null);
            setAudioLanguage("");
            setToggle(false);
        
            if (response.status === 200) {
                sendToast(`${audioName} sent to processing. We will send you an email, when it is ready.`, "success");
            } else {
                sendToast(`${audioName} could not be sent to processing. Please try again.`, "error");
            }
        } catch (error) {
            console.error(error);
            sendToast(`An error occurred while sending ${audioName} to processing. Please try again.`, "error");
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="container__upload">
            {currentStep === 1 && (
                // Step 1: Upload PDF and give it a name
                <div className="upload-step">
                   <div className="upload-information">
                        <p className="upload-step__title">Upload the CV of the Applicant (optional)</p>
                        <p className="upload-step__description">This will greatly boost the quality of the transcription and the summary and allows us to surface any inconsistencies.</p>
                   </div>
                   <div className="upload-inputs">
                        <div className="upload-step__file-inputs">
                            <label className="upload-step__label" htmlFor="pdfFile-file">Choose or Drop a File</label>
                        <input id="pdfFile-file" name="pdfFile-file" className="upload-step__file-input" type="file" accept=".pdf" onChange={(event) => {
                                setPdfFile(event.target.files[0]);
                            }} />
                        </div>
                        {pdfFile && (
                            <div className="upload-step__file-details">
                                <label className="upload-step__label" htmlFor="pdfFile-name">What's the name of the candidate?</label>
                                <input id="pdfFile-name" name="pdfFile-name" className="upload-step__name-input" type="text" placeholder="Name" value={pdfName} onChange={(event) => setPdfName(event.target.value)} />
                            </div>
                        )}
                   </div>
                    <div className="upload-controls">
                        <button className="upload-step__next-button button" onClick=    {handleNextStep}>Skip</button>
                        {pdfFile && pdfName && (<button className="upload-step__next-button button-primary" onClick={handlePdfSubmit}>Next</button>)}
                    </div>
                </div>
            )}
            {currentStep === 2 && (
                // Step 2: Upload audio, give it a name, and optionally select a language
                <div className="upload-step">
                   <div className="upload-information">
                        <p className="upload-step__title">Upload an Audio File of the Interview</p>
                        <p className="upload-step__description">Do not worry about the format, we can handle anything in audio and video. Drop a file and see the magic.</p>
                   </div>
                   <div className="upload-inputs">
                        <div className="upload-step__file-inputs">
                            <label className="upload-step__label" htmlFor="audioFile-file">Choose or Drop a File</label>
                            <input 
                                id="audioFile-file" name="audioFile-file" 
                                className="upload-step__file-input" type="file" 
                                accept=".mp3, .  wav, .aac, .flac, .ogg, .m4a, .wma" onChange={(event) => {
                                setAudioFile(event.target.files[0])
                                }} 
                            />
                        </div>
                        {audioFile && (
                            <>
                                <div className="upload-step__file-details">
                                    <label className="upload-step__label" htmlFor="audioFile-name">Give it a descriptive name, you will recognize</label>
                                    <input 
                                    id="audioFile-name" name="audioFile-name" 
                                    className="upload-step__name-input" type="text" placeholder="Name" value={audioName} onChange={(event) => setAudioName(event.target.value)} />
                                </div>
                                <div className="upload-step__file-details">
                                    <label className="upload-step__label" htmlFor="audioFile-lang">What is the language of the audio?</label>
                                    <select 
                                        id="audioFile-lang" name="audioFile-lang" 
                                        className="upload-step__language-select" value={audioLanguage} onChange={(event) => setAudioLanguage(event.target.value)}>
                                        <option value="English">English</option>
                                        <option value="Deutsch">Deutsch</option>
                                        <option value="Spanish">Spanish</option>
                                    </select>
                                </div>
                            </>
                        )}
                   </div>
                    <div className="upload-controls">
                        <button className="upload-step__next-button button" onClick={handlePreviousStep}>Go Back</button>
                        {audioFile && audioName && audioLanguage && (<button className="upload-step__next-button button-primary" onClick={(event) => {
                            if (!loading) {
                                handleAudioSubmit(event);
                            }
                        }}>Transcribe</button>)}
                    </div>
                </div>
 
            )}
        </div>
    )
}

export default UploadForm;