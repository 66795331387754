import { toast } from 'react-toastify';

export const sendToast = (message, type) => {

    switch (type) {
        case "success":
            toast.success(message, {
                position: "bottom-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
            });
            break;
        case "error":
            toast.error(message, {
                position: "bottom-center",
                autoClose: 2500,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
            });
            break;
        case "info":
            toast.info(message, {
                position: "bottom-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
            });
            break;
        case "warning":
            toast.warning(message, {
                position: "bottom-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
            });
            break;
        default:
            toast(message, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
            });
    }
}