import React, { useState, useRef } from 'react'
import { supabase } from '../utils/supabaseClient'
import { sendToast } from '../utils/sendToast'
import { Link, useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { ModeContext } from '../pages/layout';

import LogoWhite from "../assets/recapnote-logo-white.png"
import LogoBlack from "../assets/recapnote-logo-black.png"
import LoginImage from "../assets/login_recapnote.png"
import { useAuth } from "../utils/authContext";


import '../styles/pages/auth.css'

export default function Login() {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { mode, setMode } = useContext(ModeContext);

  // todo: add message handling and link to signup and link to password reset

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (!passwordRef.current?.value || !emailRef.current?.value) {
        sendToast("Please fill in the fields", "error");
        return;
      }
      const {
        data, error
      } = await login(emailRef.current?.value, passwordRef.current?.value);
      if (error) throw error;
      if (data.user && data.session) {
        sendToast("Login Successful", "success");
        navigate("/");
      }
    } catch (error) {
      sendToast(error.message, "error");
    }
    setLoading(false);
  };

  const handleMagic = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      const { error } = await supabase.auth.signInWithOtp({ email: emailRef.current?.value },
          { redirectTo: 'https://localhost:3000/' })
      if (error) throw error
    } catch (error) {
      sendToast("Something failed. Please contact the support team.", "error")
    }
    setLoading(false)
  }

  return (
    <div className="auth">
      <div className="auth-logo">
        {mode === "light" ? <img src={LogoBlack} alt="logo" /> : <img src={LogoWhite} alt="logo" />}

      </div>
      <div className="auth-container">
        <div className="auth-container-image">
          <img src={LoginImage} alt="signup" />
        </div>
        <div className="auth-container-content">
        <div className="auth-header">
          <h1 className="auth-title">Welcome back to Recapnote</h1>
          <p className="auth-description">Log in below. If you do not have an account, <Link to="/signup">Sign Up Here</Link></p>
        </div>
        <form className="form-widget" onSubmit={handleLogin}>
          <div className="inputContainer">
            <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                className="inputField"
                type="email"
                placeholder="Your email"
                ref={emailRef}
                required={true}
              />
          </div>
          <div className="inputContainer">
            <label htmlFor="password">Password</label>
            <input 
            id="password" name="password"
            type="password" className="inputField" placeholder='********'
            ref={passwordRef}
            required={true}/>
          </div>
          <div className="controlContainer">
            <div className={'button button-tertiary'} disabled={loading} onClick={handleMagic}>
              {loading ? <span>Loading</span> : <span>Send magic link</span>}
            </div>

            <button className={'button button-primary'} disabled={loading}>
              {loading ? <span>Loading</span> : <span>Log In</span>}
            </button>
          </div>
        </form>
      </div>
      </div>
    </div>
  )
}