import React, { useState, useEffect } from 'react';

import { supabase } from "../utils/supabaseClient.js"
import { useAuth } from "../utils/authContext.js";

import Transcription from './transcription';

import '../styles/components/gallery.css';


const Gallery = () => {
  const { user } = useAuth();
  const [transcriptions, setTranscriptions] = useState([])
    

  useEffect(() => {
      const fetchTranscriptions = async () => {
          try {
              const response = await supabase
                  .from("transcripts")
                  .select("*")
                  .eq("user_email", user.email);
              
              setTranscriptions(response.data);
          } catch (error) {
              console.error("Error fetching transcriptions:", error);
          }
      };

      fetchTranscriptions();
  }, [])

  // Initialize state variables for sorting
  const [sortBy, setSortBy] = useState('date'); // Default sorting by date
  const [sortOrder, setSortOrder] = useState('asc'); // Default sorting order

  // Function to handle sorting by date
  const sortByDate = () => {
    if (sortBy === 'date') {
      // Toggle sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If currently sorting by length, switch to sorting by date
      setSortBy('date');
      setSortOrder('asc');
    }
  };

  // Function to handle sorting by length
  const sortByLength = () => {
    if (sortBy === 'length') {
      // Toggle sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If currently sorting by date, switch to sorting by length
      setSortBy('length');
      setSortOrder('asc');
    }
  };

  // Sort the transcriptions based on the selected sorting criteria
  const sortedTranscriptions = [...transcriptions].sort((a, b) => {
    if (sortBy === 'date') {
      return sortOrder === 'asc'
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    } else if (sortBy === 'length') {
      return sortOrder === 'asc'
        ? a.length - b.length
        : b.length - a.length;
    }
  });

  return (
    <div className="gallery-container">
      <div className="gallery-sort">
        <button onClick={sortByDate} className="sort-button">Sort by Date</button>
        <button onClick={sortByLength} className="sort-button">Sort by Length</button>
      </div>
      <div className="gallery-seperator"></div>
      <div className="gallery">
        {sortedTranscriptions.map(transcription => <Transcription transcription={transcription} />)}
      </div>
    </div>
  )
};

export default Gallery;