import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoWhite from "../assets/recapnote-logo-white.png"
import LogoBlack from "../assets/recapnote-logo-black.png"
import Button from "./button"


import { useAuth } from '../utils/authContext';

import SignOut from './signout';
import "../styles/components/navbar.css"
import { useContext } from 'react';
import { ModeContext } from '../pages/layout';

const NavBar = () => {
  const { auth, user } = useAuth();
  const { pathname } = useLocation();
  const { mode, setMode } = useContext(ModeContext);

  
  return (<nav className="navbar">
    <Link className="navbar__logo" to="/">
      {mode === "light" ? <img src={LogoBlack} alt="logo" /> : <img src={LogoWhite} alt="logo" />}
    </Link>
    <div className="darkmode-toggle-wrapper">
      <input type="checkbox" id="darkmode-toggle" aria-label="dark mode toggle" className="darkmode-toggle-checkbox" 
        onChange={() => {
          // Use a function to set the new mode based on the current mode
          setMode((current) => {
            return current === "light" ? "dark" : "light";
          });}}/>
      <div className="darkmode-toggle-circle">
      </div>
      <span className="darkmode-toggle-emoji">🌚</span>
      <span className="darkmode-toggle-emoji">🌞</span>
    </div>
    <div className="navbar__links">
      {
        auth ?  <SignOut /> : (<><Link className={pathname === "/login" ? "navbar-link navbar-link--active" : "navbar-link"} to="/login">Login</Link><Link className={pathname === "/signup" ? "navbar-link navbar-link--active" : "navbar-link"} to="/signup">Signup</Link></>)
      }
    </div>
  </nav>)
};

export default NavBar;