import React from "react";

import "../styles/components/button.css"

const Button = ({ children, onClick, classname, styles }) => {
    return (
        <button onClick={onClick} className={classname ? `button button-${classname}` : "button"}
            style={styles}>
        {children}
        </button>
    );
}

export default Button;