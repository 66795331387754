import { useRef, useState } from "react";
import { useAuth } from "../utils/authContext";
import { Link } from "react-router-dom";

import "../styles/pages/auth.css"

const PasswordReset = () => {
    const { passwordReset } = useAuth();
    const emailRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");

    // todo: add message handling and link to login

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
        setLoading(true);
        const { data, error } = await passwordReset(emailRef.current.value);
        console.log(error);
        console.log(data);
        setMsg("Password reset has been sent to your email");
        } catch (e) {
        console.log(e);
        }
        setLoading(false);
    };

    return (
        <div className="auth">
          <div className="auth-logo">
            <img src={Image} alt="Recapnote logo" />
          </div>
          <div className="auth-container">
            <div className="auth-header">
              <h1 className="auth-title">Welcome to Recapnote</h1>
              <p className="auth-description">Log in below</p>
            </div>
            <form className="form-widget" onSubmit={handleSubmit}>
              <div className="inputContainer">
                <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    name="email"
                    className="inputField"
                    type="email"
                    placeholder="Your email"
                    ref={emailRef}
                    required={true}
                  />
              </div>
              <div className="controlContainer">
                <div className="emptyControl"></div>
    
                <button className={'button button-primary'} disabled={loading}>
                  {loading ? <span>Loading</span> : <span>Send Reset Link</span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      )
}

export default PasswordReset;