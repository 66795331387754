import React from 'react';

import { Link } from "react-router-dom";

import { renderDayFromTimestamp, renderDurationString } from "../utils/utilitiesRendering.js";

import '../styles/components/transcription.css';

const Transcription = ({transcription}) => {
  return (<Link to={`/transcription/${transcription.transcript_id}`}>
    <div className="transcription">
      <h3 className="transcription__title">{transcription.title}</h3>
      <p className="transcription__summary">{transcription.summary}</p>
      <p className="transcription__metadata">
        <div className="transcription__metadata-item">
          Length: {renderDurationString(transcription.length)}
        </div>
        <div className="transcription__metadata-item">
          Date: {renderDayFromTimestamp(transcription.created_at)}
        </div>
      </p>
    </div>
  </Link>)
};

export default Transcription;