import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "./supabaseClient";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

const login = (email, password) =>
  supabase.auth.signInWithPassword({ email, password });

const signOut = () => supabase.auth.signOut();

const passwordReset = (email) =>
  supabase.auth.resetPasswordForEmail(email, {
    redirectTo: "http://localhost:5173/update-password"
  });

const updatePassword = (updatedPassword) =>
  supabase.auth.updateUser({ password: updatedPassword });

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  // todo: add a loading page
  useEffect(() => {
    const session = supabase.auth.getSession();
    console.log("session, get session", session)
    setUser(session.user ?? null);
    setAuth(!!session.session);

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log(`Supabase auth event: ${event}`, session?.user)
        setUser(session?.user ?? null);
        setAuth(!!session);
        setLoading(false);

        if (event === "PASSWORD_RECOVERY") {
          setAuth(false);
        } else if (event === "SIGNED_IN") {
          setUser(session.user);
          setAuth(true);
        } else if (event === "SIGNED_OUT") {
          setUser(null);
          setAuth(false);
        } 
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ auth, user, login, signOut, passwordReset, updatePassword }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;