import React from 'react'
import { supabase } from '../utils/supabaseClient'

import Button from "./button.js"

const SignOut = () => {
    const handleSignOut = async () => {
        await supabase.auth.signOut()
    }
    
    return (
        <Button onClick={handleSignOut} classname={"secondary"}>Sign out</Button>
    )
}

export default SignOut;