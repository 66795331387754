import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import Navbar from "../components/navbar"

import "../styles/pages/layout.css"
import 'react-toastify/dist/ReactToastify.css';

export const ModeContext = React.createContext();

const Layout = () => {
    const [mode, setMode] = React.useState("light");

    return (
        <ModeContext.Provider value={{ mode, setMode }}>
            <ToastContainer theme={mode=== "light" ? "light" : "dark"}/>
            <div className={`App App--${mode}`}>
                <Navbar />
                <div className="main">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Outlet />
                    </Suspense>
                </div>
                {/* <Footer />*/}
            </div>
        </ModeContext.Provider>
    )
}

export default Layout;