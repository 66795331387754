import React from 'react';

import "../styles/pages/tos.css"

export default function TOS() {
  return (
    <div className="agbs-container">
      <h1>General Terms and Conditions</h1>
      <h2>1. Data Processing and User Consent</h2>
      <p>
        1.1 By using recapnote, the user (“You”) explicitly consents to the collection, processing, and use of their data by recapnote (“We”, “Us”, “Our”) in accordance with these General Terms and Conditions and our Privacy Policy.
      </p>
      <p>
        1.2 We adhere to applicable data protection laws and regulations and prioritize the safeguarding of Your data against unauthorized access, alteration, disclosure, or destruction.
      </p>
      <h2>2. User Responsibilities and Rights Assignment</h2>
      <p>
        2.1 You affirm that You possess the legal authority and rights to process, utilize, and share any data You upload to recapnote, including data originating from third parties.
      </p>
      <p>
        2.2 You hereby grant Us a non-exclusive, transferable, royalty-free, worldwide license to use, copy, modify, and process the data You provide for purposes that include, but are not limited to, model training and data analysis.
      </p>
      <p>
        2.3 It is Your responsibility to secure and substantiate any necessary consents or permissions related to third-party data prior to sharing it with recapnote. You agree to indemnify and hold Us harmless from any claims, liabilities, damages, or costs arising from Your failure to obtain such consents or permissions.
      </p>
      <h2>3. Data Usage and Sharing</h2>
      <p>
        3.1 We pledge not to sell, share, or transfer Your data to any third parties unless otherwise specified in our Privacy Policy or as required by law.
      </p>
      <p>
        3.2 Your data will only be used for purposes related to enhancing, developing, and maintaining recapnote and its services, including model training and further analysis as specified.
      </p>
      <h2>4. Limitation of Liability</h2>
      <p>
        4.1 recapnote shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from the use of recapnote.
      </p>
      <h2>5. Changes to the Terms</h2>
      <p>
        5.1 We reserve the right to modify these General Terms and Conditions at any time. It is Your responsibility to review these Terms periodically. Your continued use of recapnote constitutes Your agreement to be bound by any changes to these Terms.
      </p>
      <h2>6. User Content</h2>
      <p>
      6.1 You understand that all information, data, text, software, graphics, video, messages, tags, or other materials ("Content"), whether publicly posted or privately transmitted, are the sole responsibility of the person from whom such Content originated.
      </p>
      <p>
      6.2 You agree that recapnote is not responsible for the accuracy, completeness, or utility of any Content provided by You or third parties. recapnote does not pre-screen Content but retains the right to refuse or remove any Content available through recapnote.
      </p>
      <h2>7. Governing Law and Jurisdiction</h2>
      <p>
        7.1 These General Terms and Conditions shall be governed by and construed in accordance with German Law. Any disputes arising out of or relating to these Terms or Your use of recapnote will be subject to the exclusive jurisdiction of Germany.
      </p>
      <h2>8. Miscellaneous</h2>
      <p>
        8.1 If any provision of these Terms is found to be invalid or unenforceable by a court of law, such invalidity or unenforceability will not affect the remainder of the Terms which will continue in full force and effect.
      </p>
      <p>
        8.2 These Terms constitute the entire agreement between You and recapnote and supersede any prior representations, understandings, or agreements obtained or received by the User.
      </p>
    </div>
  );
}
