import React, { useState } from 'react';

import UploadForm from "../components/uploadform"
import Gallery from "../components/gallery"

import "../styles/pages/home.css"

const Home = () => {
    const [toggle, setToggle] = useState(false)


    return (
        <div className="home">
            <div id="message"></div>
            <div className="toggle-upload-container">
              <div 
                onClick={() => setToggle(!toggle)} 
                className={`toggle-upload ${toggle ? "toggle-upload--toggled" : ""}`}>
              {!toggle ? <svg className="toggle-icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg> : <svg className="toggle-icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>}
              <span>Click to Upload</span>
            </div>
            {toggle ? <UploadForm setToggle={setToggle}/> : null}
            </div>
            <Gallery />              
        </div>
    )
}

export default Home; 
