import React from "react";

export function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;
  
    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
  
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
  
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
  
    return ret;
}

export function renderDayFromTimestamp(timestamp, format = 'short') {
    // Extract the date part from the timestamp
    const datePart = timestamp.split("T")[0];
  
    // Check the format
    if (format === 'long') {
        // Create a new Date object from the date part
        const date = new Date(datePart);
        
        // Create an array of month names
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"];
        
        // Return the date in long format (e.g. September 9th 2023)
        return `${monthNames[date.getMonth()]} ${date.getDate()}th ${date.getFullYear()}`;
    }
  
    // Return the date part in short format (YYYY-MM-DD)
    return datePart;
}

export function renderDurationString(duration, format = 'standard') {
    // Split the duration string into minutes and seconds
    let [mins, secs] = duration.split(":").map(Number);
  
    // Check if seconds is less than 10 and add the missing 0
    if (secs < 10) {
        secs = "0" + secs;
    }
  
    // Check the format
    if (format === 'fancy') {
        // Render the duration string in a fancy format (e.g. 42 Minutes and 34 Seconds)
        return `${mins} Minute${mins > 1 ? 's' : ''} and ${secs} Second${secs > 1 ? 's' : ''}`;
    }
  
    // Return the duration string in standard format (MM:SS)
    return `${mins}:${secs}`;
}
